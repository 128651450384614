import { Theme as ThemeName } from '@/contexts/interfaces';
import type { AnyMap } from '@/types/types';
import type { Map } from 'immutable';

export enum SettingModelActions {
  FETCH_SETTING_SUCCESS = 'FETCH_SETTING_SUCCESS',
  SYNC_INTEGRATIONS = 'SYNC_INTEGRATIONS',
  UPDATE_INTEGRATIONS_LOADING = 'UPDATE_INTEGRATIONS_LOADING',
  SET_THEME = 'SET_THEME',
}

export enum SettingsModelStateFields {
  USER_SETTING = 'userSetting',
  GROUP_SETTING = 'groupSetting',
  FEATURE_FLAG = 'featureFlags',
  FEATURE_FLAG_SETTINGS = 'featureFlagsSettings',
  MODULES = 'modules',
  RUNTIME_SETTINGS = 'runtimeSettings',
  CURRENCY_SETTING = 'currency',
  SYNC_INTEGRATIONS_LOADING = 'syncIntegrationsLoading',
  PERMISSION = 'permissions',
  PRODUCT_METADATA = 'productMetadata',
}

type SettingModelStateProps = {
  [SettingsModelStateFields.USER_SETTING]: AnyMap;
  [SettingsModelStateFields.GROUP_SETTING]: GroupSettingInterface;
  [SettingsModelStateFields.FEATURE_FLAG]: AnyMap;
  [SettingsModelStateFields.FEATURE_FLAG_SETTINGS]: AnyMap;
  [SettingsModelStateFields.MODULES]: AnyMap;
  [SettingsModelStateFields.RUNTIME_SETTINGS]: AnyMap;
  [SettingsModelStateFields.CURRENCY_SETTING]: CurrencySetting;
  [SettingsModelStateFields.PERMISSION]: AnyMap;
  [SettingsModelStateFields.PRODUCT_METADATA]: ProductMetadata;
  [SettingsModelStateFields.SYNC_INTEGRATIONS_LOADING]: boolean;
};

export type SettingModelState = Map<keyof SettingModelStateProps, SettingModelStateProps[keyof SettingModelStateProps]>;

// User settings
export interface UserSettingInterface extends AnyMap {
  [UserSetting.THEME]: Theme;
}

export enum UserSetting {
  THEME = 'theme',
  REPORT_NEW_VERSION = 'report_new_version',
  CAN_VIEW_RATES = 'can_view_rates',
  CAN_CREATE_PROJECTS = 'canCreateProjects',
  DO_NOT_SUBMIT_APPROVAL = 'doNotSubmitApproval',
  CREATE_PROJECTS = 'create_projects',
  TIME_TRACKING_ADMIN = 'time_tracking_admin',
  LAST_USED_TIMESHEET = 'lastUsedTimesheet',
  LAST_TASKS = 'last_tasks',
  TIMESHEET_SORTING = 'timesheets_sorting',
  FAVORITES_TASKS = 'userFavoritesTasks',
  SUGGESTED_TASKS = 'userSuggestedTasks',
  START_TIMER_WITH_NOTE = 'userStartTimerWithNote',
  TIMER_NEW_ENTRY_EACH = 'timesheets_create_new_entry_on_each_timer',
  TIMER_AUTO_FILL = 'timesheets_timer_time_auto_fill',
}

export enum CurrencySettingEnum {
  CURRENCY = 'Currency',
}

export enum ProductMetadataSetting {
  PRODUCT_NAME = 'productName',
}

export enum SettingModule {
  TAGS = 'tags',
  BILLING = 'billing',
  INVOICING = 'invoicing',
  APPROVALS = 'approvals',
  TIMESHEET = 'timesheet',
}

export enum Theme {
  DEFAULT = 'default',
  DARKMODE = 'darkmode',
}

export const ThemeDictionary = {
  [Theme.DEFAULT]: ThemeName.MODERN,
  [Theme.DARKMODE]: ThemeName.DARK,
};

// Group settings
export interface GroupSettingInterface extends AnyMap {
  [GroupSetting.WEEK_START]: WeekStart;
  [GroupSetting.DURATION_FORMAT]: DurationFormat;
}

export type CurrencySetting = {
  id: string;
  name: string;
  symbol: string;
  symbolPosition: string;
};

export enum GroupSetting {
  DURATION_FORMAT = 'durationFormat',
  WEEK_START = 'weekStart',
  TIME_FORMAT = 'timeFormat',
  DATE_FORMAT = 'dateFormat',
  APPROVAL_PERIOD = 'approvalPeriod',
  CHANGE_BILLING_FLAG = 'changeBillingFlag',
  OVERLAPING_TIME_ENTRIES = 'preventOverlappingEntries',
  ALLOW_TRACKING_TIME_FOR_ARCHIVED_TASKS = 'allowTrackingTimeForArchivedTasks',
  ENABLED_TIMESHEETS = 'enabledTimesheets',
  DISALLOW_FORCE_SYNCH_FOR_NON_ADMIN = 'disallowForceSynchForNonAdmin',
}

export enum RuntimeSetting {
  ACTUAL_OPENED_DROPDOWN = 'actualOpenedDropdown',
  TITLE = 'title',
}

export enum DurationFormat {
  FORMAT_CLASSIC,
  FORMAT_HH_MM,
  FORMAT_DECIMAL_WITH_COMMA,
  FORMAT_DECIMAL_WITH_DOT,
  FORMAT_CLASSIC_WITH_SECONDS,
  FORMAT_HH_MM_SS,
}

export enum WeekStart {
  SUNDAY = 'SUNDAY',
  MONDAY = 'MONDAY',
}

export enum TimeFormat {
  FORMAT_24H,
  FORMAT_12H,
}

export enum TimeFormatDict {
  FORMAT_24H = 'FORMAT_24H',
  FORMAT_12H = 'FORMAT_12H',
}

export enum DateFormat {
  FORMAT_YMD,
  FORMAT_MDY,
  FORMAT_DMY,
}

export enum DateFormatDict {
  FORMAT_YMD = 'FORMAT_YMD',
  FORMAT_MDY = 'FORMAT_MDY',
  FORMAT_DMY = 'FORMAT_DMY',
}

export enum ApprovalPeriod {
  DAILY = 'daily',
  WEEKLY = 'weekly'
}

// Feature flag
export enum FeatureFlag {
  REPORT_EXPORT_TO_PDF = 'REPORT_EXPORT_TO_PDF',
  REPORT_EXPORT_TO_OTHER_FORMAT = 'REPORT_EXPORT_TO_OTHER_FORMAT',
  ACCESS_TO_SUMMARY_REPORT_V_2022 = 'ACCESS_TO_SUMMARY_REPORT_V_2022',
  ACCESS_TO_DETAILED_REPORT_V_2022 = 'ACCESS_TO_DETAILED_REPORT_V_2022',
  ACCESS_TO_GOOGLE_API_FEATURE = 'ACCESS_TO_GOOGLE_API_FEATURE',
  CAN_CREATE_PROJECTS_MANUALLY = 'CAN_CREATE_PROJECTS_MANUALLY',
  CAN_HAVE_USER_GROUPS = 'CAN_HAVE_USER_GROUPS',
  ACCESS_TO_EXPORTER = 'ACCESS_TO_EXPORTER',
}

export enum TimesheetType {
  CALENDAR = 'graphical',
  DAILY = 'textual',
  WEEK = 'weekly'
}

export enum Permission {
  ADMINISTRATION_RIGHTS = 'administrationRights'
}

export type ProductMetadata = {
  [ProductMetadataSetting.PRODUCT_NAME]: string,
};
